<template>
  <div class="pt-0 pb-0">
    <Component
      v-for="section of sections"
      :is="getSectionComponent(section._type)"
      v-bind="section"
    />
  </div>
</template>

<script lang="ts" setup>
import type {
  SanityHome,
  SanitySectionInformation,
  SanitySectionRow,
} from '@/types/sanity';
import { querySanityHome } from '@/queries/sanity';
import HomeBanner from '@/components/HomeBanner.vue';
import HomeInformation from '@/components/HomeInformation.vue';

const sanity = useSanity();
const { data: sections } = await useAsyncData(
  'home',
  () => sanity.fetch<SanityHome>(querySanityHome.query),
  {
    transform: (data) => {
      return (data.sections || []).filter(
        (s): s is SanitySectionInformation | SanitySectionRow => '_type' in s,
      );
    },
  },
);

const getSectionComponent = (
  _type: SanitySectionInformation['_type'] | SanitySectionRow['_type'],
) => {
  return {
    sectionRow: HomeBanner,
    sectionInformation: HomeInformation,
  }[_type];
};
</script>
